@tailwind base;
@tailwind components;
@tailwind utilities;

/* Üst menü çubuğu */
.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  height: 60px; /* Sabit yükseklik */
}
.welcome-text {
  text-align: center;
  font-size: 14px;  
  color: #666;
  padding: 0;  
  margin: 5px 0; 
  line-height: 1; 
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo img {
  height: 30px;
  width: auto;
}

.logo h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

/* Tab menüsü */
.tab-menu {
    display: flex;
    justify-content: space-around;
    margin: 70px 0 20px;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 20px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tab-link {
    flex: 1;
    padding: 12px;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 30px;
    margin: 0 10px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: bold;
}

.tab-link.active {
    background-color: #ddd;
}

/* İstatistikler */
/* İstatistikler */
.profit-summary {
  margin: 15px 0;
}

.profit-summary table {
  width: 100%;
  background-color: rgb(231, 250, 255);
  border-collapse: collapse;
  font-size: 18px; /* Yazı boyutunu küçülttük */
  font-weight: bold;
}

.profit-summary tr:nth-child(odd) {
  background-color: #e7faff;
}

.profit-summary tr:nth-child(even) {
  background-color: #d9f2f8;
}

.profit-summary td {
  padding: 10px 10px; /* Padding'i azalttık */
}

.profit-summary td:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profit-summary h4 {
  margin: 0;
  font-size: 14px; /* Başlık boyutunu küçülttük */
  font-weight: 500;
}

.profit-summary i {
  font-size: 16px; /* Icon boyutunu küçülttük */
  width: 16px; /* Sabit genişlik */
}

/* Pozisyon kartları */
.position-card {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 8px 12px;
    cursor: pointer;
    height: 56px; /* Sabit yükseklik */
}

.position-card img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-right: 12px;
}

.card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.card-symbol {
    font-size: 14px;
    font-weight: bold;
    margin-right: auto;
}

.card-strategy {
    font-size: 12px;
    color: #888;
    margin-left: 8px;
}

/* Profit/Loss renkleri */
.profit-positive { color: green; }
.profit-negative { color: red; }




.modal {
  display: flex;  /* Flexbox kullanarak ortalama */
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px; /* Mobil için kenar boşluğu */
}

.modal-content {
  background-color: #fefefe;
  width: 100%;
  max-width: 450px; /* Maksimum genişlik */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Yatay ortalama */
  max-height: 90vh; /* Maksimum yükseklik */
  overflow-y: auto; /* Gerekirse scroll */
  position: relative; /* Close button için */
}

/* Mobil için düzenlemeler */
@media (max-width: 768px) {
  .modal {
    padding: 0;
  }
  
  .modal-content {
    max-width: 95%;
    border-radius: 8px;
    margin: auto 10px;
  }
}

/* Header kısmı için düzenlemeler */
.header-content {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin: -20px -20px 15px -20px;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Table container için düzenlemeler */
.table-container {
  max-height: calc(70vh - 150px);
  overflow-y: auto;
  margin: 0 -20px;
  padding: 0 20px;
}
/* Logo and Header Styles */
.coin-logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.header-content {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.symbol-info {
  flex-grow: 1;
}

.symbol-text {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.strategy-text {
  font-size: 12px;
  color: #666;
  margin: 0;
}

/* Close Button */
.modal-close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 24px;
  font-weight: normal;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.modal-close:hover {
  color: #000;
}

/* Table Container with Scroll */
.table-container {
  max-height: 400px; /* Maksimum yükseklik */
  overflow-y: auto; /* Dikey scroll */
  margin: -5px; /* Negative margin to counter parent padding */
  padding: 5px; /* Padding to maintain spacing */
}

/* Scrollbar Styles */
.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Table Styles */
.message-table {
  width: 100%;
  border-collapse: collapse;
}

.message-table th {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 2px solid #eee;
  position: sticky; /* Header'ı sabitle */
  top: 0; /* En üstte */
  z-index: 1; /* Header'ı içeriğin üzerinde tut */
  background-color: #f8f9fa; /* Header arka plan rengi */
}

.message-table td {
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

/* Zebra Striping */
.message-table tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.message-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

/* Footer */
.modal-footer {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  font-size: 14px;
  color: #666;
}





/* Mobil görünüm */
@media screen and (max-width: 768px) {
    .position-card {
        padding: 8px;
    }

    .card-content {
        gap: 8px;
    }

    .card-timestamp {
        font-size: 12px;
        color: #888;
        text-align: right;
    }
}


/* Login sayfası stilleri */
/* Login sayfası stilleri */
.login-container {
  min-height: calc(100vh - 61px);
  margin-top: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%); /* Güzel bir mavi gradient */
  /* veya */
  /* background: linear-gradient(to right, #0f2027, #203a43, #2c5364); */ /* Koyu mavi gradient */
  /* veya */
  /* background: linear-gradient(45deg, #000428, #004e92); */ /* Gece mavisi gradient */
}

.login-content {
  background: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  width: 100%;
  max-width: 400px;
  margin: 20px;
}

.login-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.login-content h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 30px;
}

.login-button {
  background-color: #4285f4;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  margin: 0 auto;
}

.login-button:hover {
  background-color: #3367d6;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.login-button i {
  font-size: 20px;
}

/* Container için responsive ayarlar */
@media screen and (max-width: 480px) {
  .login-content {
      padding: 30px 20px;
      margin: 15px;
  }

  .login-button {
      width: 100%;
  }
}


/* Daha fazla göster butonu */
.show-more-button {
  width: 100%;
  padding: 12px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.2s ease;
}

.show-more-button:hover {
  background-color: #e9ecef;
  border-color: #ccc;
}


 

.sound-toggle:hover {
  background-color: #f0f0f0;
}

.sound-toggle.muted {
  color: #999;
}

.sound-toggle.muted:hover {
  background-color: #f5f5f5;
}

.menu-controls {
  display: flex;
  align-items: center;
  gap: 6px; /* Butonlar arası boşluk */
}

.sound-toggle {
  background: none;
  border: none;
  color: #666;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.exit-button {
  border: 1px solid #ddd;
  background: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
}

.exit-button:hover {
  background-color: #f0f0f0;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.coin-logo {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.symbol-info {
  flex: 1;
}

.symbol-text {
  font-size: 16px;
  font-weight: 600;
}

.strategy-text {
  font-size: 12px;
  color: #666;
}

.action-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 15px;
}

.tv-button {
  background-color: #2962ff;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.tv-button:hover {
  background-color: #1939b7;
}

.bybit-button {
  background-color: #f7a600;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.bybit-button:hover {
  background-color: #d68f00;
}


.pnl-update-button {
  background-color: #1d6614;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.pnl-update-button:hover {
  background-color: #0a5c47;
}


.close {
  margin-left: 10px;
  font-size: 20px;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 8px;
}

.close:hover {
  color: #000;
}

/* modal.css */
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
}

.delete-button {
  background-color: #ff0000;
  color: white;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  
}

.pnl-container {
  display: flex;
  align-items: center;
}

.delete-button {
  /* Silme butonunun stilleri */
  margin-left: 10px; /* PNL değerinden biraz uzaklaştır */
}



.loading-screen,
.error-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.loading-content,
.error-content {
  text-align: center;
  padding: 2rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-content button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-content button:hover {
  background: #2980b9;
}